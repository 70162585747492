.connect-vechile-title{
        font-size: 2rem;
        font-family: "AvenirNext Bold",sans-serif;
        letter-spacing: 0.03px;
        color: #6d6d6d;
        font-weight: 700;
}
.connect-btn{
    background: #D63827;
    padding: 3px 15px;
    color: #fff;
    border: 0;
    font-size: 1.8rem;
    font-family: "AvenirNext Medium",sans-serif;
    letter-spacing: 0.03px;
}
.connect-content{
    margin-top: 140px;
}
.connect-container{
    background-color: #f0f1f4;
    border-radius: 5px;
    padding-bottom: 120px;
}